//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapMutations, mapActions} from 'vuex';
import ReturnModal from 'razlet-sdk/lib/components/avia/return/return-modal';

export default {
  components: {
    ReturnModal,
  },
  data() {
    return {
      isPassengersDropdownOpened: false,
      isReturnWindowOpened: false,
      typeReturn: null,
    };
  },
  computed: {
    locales() {
      return this.$i18n.availableLocales;
    },
  },
  watch: {
    '$locale': {
      handler(value) {
        this.$cookiz.set('defaultLanguage', value, {path: '/', maxAge: 60 * 60 * 24 * 365});
        this.initSuluSender({requests: ['getMenu']});
        this.$bus.$emit('locale-changed');
      },
    },
    currentTheme: {
      handler (value) {
        this.$cookiz.set('currentTheme', value, { path: '/', maxAge: 60 * 60 * 24 * 365 });
        if (process.client) this.changeColorScheme();
      },
    },
  },
  methods: {
    ...mapMutations(['setTheme']),
    ...mapActions('sulu', ['initSuluSender']),
    hidePassengersDropdown() {
      this.isPassengersDropdownOpened = false;
    },
    returnTicket() {
      if (this.isAgent) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchange';
        this.isReturnWindowOpened = true;
      }
    },
    findOrder() {
      this.typeReturn = 'search';
      this.isReturnWindowOpened = true;
    },
    goToFAQ() {
      this.$router.push('/faq');
    },
  },
};

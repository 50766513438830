import {mapActions, mapMutations} from 'vuex';
import {REFRESH, LOGOUT} from 'razlet-auth/src/store/actions.types';
import {GET_USER_AND_BALANCE} from 'razlet-sdk/lib/store/auth/actions.type';
import {SET_LOGGED} from 'razlet-sdk/lib/store/auth/mutations.type';
// import {isLocalStorageAvailable} from 'razlet-sdk/lib/utils/helpers';

export default {
  watch: {
    '$route': {
      async handler(route) {
        await this.checkAuth(route);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$bus.$on('login', () => {
      this.syncTabsLoggedStatus('true');
    });
    this.$bus.$on('switch', () => {
      this.switch();
    });
    this.$bus.$on('logout', () => {
      this.syncTabsLoggedStatus('false');
    });
    window.addEventListener('storage', this.processLoggedEvent, false);
  },
  destroyed() {
    this.$bus.$off('login');
    this.$bus.$off('switch');
    this.$bus.$off('logout');
    window.removeEventListener('storage', this.processLoggedEvent, false);
  },
  methods: {
    ...mapActions('auth', [GET_USER_AND_BALANCE]),
    ...mapActions([REFRESH, LOGOUT]),
    ...mapMutations('auth', [SET_LOGGED]),
    getUser(route) {
      this.getUserAndBalance(this.$store).then(response => {
        this.checkRole(route, response[1].data.roles);
        this.$bus.$emit('user-balance-loaded');
      });
    },
    checkRole(route, roles) {
      const {access} = route.meta;
      if (route.meta && access && access.length && roles && !access.some(v => roles.includes(v))) {
        this.goToBadAccess();
      }
    },
    switch() {
      this.globalMountKey += 1;
      this.syncTabsLoggedStatus('switching');
      this.syncTabsLoggedStatus('true');
      this.getUser(this.$route);
    },
    syncTabsLoggedStatus(val) {
      this.processLoggedEvent({ key: 'logged', oldValue: null, newValue: val});
      localStorage.setItem('logged', val);
    },
    processLoggedEvent(e) {
      if (e.key === 'logged' && e.oldValue !== e.newValue) {
        this.setLogged(e.newValue);
        if ((e.oldValue === 'false' || e.oldValue === 'switching') && e.newValue === 'true') {
          this.refreshAction().then(() => {
            this.getUser(this.$route);
          });
        }
        if (e.newValue === 'false') {
          this.logoutAction().then(() => {
            this.goHome();
          });
        }
      }
    },
    logout() {
      this.syncTabsLoggedStatus('false');
      this.logoutAction().then(() => this.goHome());
    },
    goToError() {
      this.$router.push({ name: 'bad-access' });
      this.syncTabsLoggedStatus('false');
    },
    goHome() {
      if (this.$route.path !== '/') this.$router.push(`/`);
    },
    goLoginPage() {
      this.$router.push(`/login?path=${btoa(this.$route.fullPath)}`);
    },
    goToBadAccess() {
      this.$router.push(`/bad-access`);
    },
    async checkAuth(route) {
      if (process.client) {
        if (!this.$store.getters.token && !this.$store.getters.loading && this.$store.state.sdk.auth.logged !== 'false') {
          const token = await this.$interceptor.intercept();
          if (token) {
            // авторизация прошла успешно
            this.getUser(route);
            this.syncTabsLoggedStatus('true');
          } else if (this.$store.state.sdk.auth.logged === 'true') {
            // если не авторизован на сервере, но авторизован в приложении
            this.syncTabsLoggedStatus('false');
          } else {
            localStorage.setItem('logged', 'false');
            this.setLogged('false');

            if (route.meta.access) {
              this.goLoginPage();
            }
          }
        } else if (!this.$store.getters.token && route.meta.access) {
          this.goLoginPage();
        } else if (this.$store.getters.token && route.meta.access && this.$store.getters.user.model.roles) {
          this.checkRole(route, this.$store.getters.user.model.roles);
        }
      }
    },
  },
};
//
//
//
//
//
//
//
//
//
//
//

import DesktopHeader from '~/components/desktop-header';
import MobileHeader from '~/components/mobile-header';

export default {
  name: 'main-header',
  components: {
    MobileHeader, 
    DesktopHeader, 
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from '~/components/main-header';
import FooterBlock from '~/components/footer-block';
import auth from '~/mixins/auth';

export default {
  components: {
    MainHeader,
    FooterBlock,
  },
  mixins: [auth],
};
